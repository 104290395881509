import { TAnnouncement, TAnnouncementPlacement } from 'submodule/Announcements/types';
import {
	useFlexibleBillingAvailableAnnouncement,
	useNewLanguagesAnnouncement,
	useOmsDowntimeAnnouncement,
	useTaxExemptionCertificateAnnouncement,
	useCCPaymentSuspensionAnnouncement,
} from 'submodule/Announcements/data';

export const useAnnouncements = (placement: TAnnouncementPlacement): TAnnouncement[] => {
	const list: TAnnouncement[] = [
		useTaxExemptionCertificateAnnouncement(),
		useNewLanguagesAnnouncement(),
		useFlexibleBillingAvailableAnnouncement(),
		useOmsDowntimeAnnouncement(),
		useCCPaymentSuspensionAnnouncement(),
	];

	return list.filter((announcement) => announcement.show && announcement.placement.includes(placement));
};
