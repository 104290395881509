import React from 'react';
import { Alert, Card, CardBody, CardFooter, CardHeader } from '@avast/react-ui-components';
import { useAutoBillingCurrentSource } from 'module/account/hooks/useAutoBillingCurrentSource';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { CreditCardPreviewRow } from 'module/account/components/autoBilling/CreditCardPreviewRow';
import { CreditCardPreviewTable } from 'module/account/components/autoBilling/CreditCardPreviewTable';
import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { useAutoBillingDeleteSource } from 'module/account/hooks/useAutoBillingDeleteSource';

export const AutoBillingCurrentSource = () => {
	const [t] = useTranslation(accountConfig.trNamespace);
	const { source, isFetching, error } = useAutoBillingCurrentSource();
	const deleteSource = useAutoBillingDeleteSource(source);

	return (
		<Card>
			<CardHeader>
				<div>{t('creditCard.currentSource.title')}</div>
				<p className="text-gray fs-md m-0 fw-semibold">{t('creditCard.currentSource.subtitle')}</p>
			</CardHeader>
			<CardBody>
				{(() => {
					if (isFetching) {
						return <LoadingPlaceholder ellipsis>{t('creditCard.currentSource.loading')}</LoadingPlaceholder>;
					}

					if (error) {
						return <Alert variant="danger">{error}</Alert>;
					}

					if (!source) {
						return <Alert variant="warning">{t('creditCard.currentSource.notFound')}</Alert>;
					}

					return (
						<CreditCardPreviewTable>
							<CreditCardPreviewRow
								paymentOption={source}
								single
								onDelete={deleteSource}
							/>
						</CreditCardPreviewTable>
					);
				})()}
			</CardBody>
			{!isFetching && !error && !source && (
				<CardFooter className="text-center">
					<LinkButton
						to={RouteName.ACCOUNT.AUTO_BILLING_ADD_CARD}
						size="sm"
					>
						{t('creditCard.currentSource.create')}
					</LinkButton>
				</CardFooter>
			)}
		</Card>
	);
};
