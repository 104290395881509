import React, { ReactElement } from 'react';
import { AutoBillingCurrentSource } from 'module/account/components/autoBilling/AutoBillingCurrentSource';
import { Col, Row } from 'js/components/atoms/Row';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { PaymentMethodCard } from 'module/account/components/flexibleBilling/PaymentMethodCard';

export const PageFlexibleBilling = (): ReactElement => {
	return (
		<div className="text-center">
			<FixedWidthWrapper
				width={1200}
				max
			>
				<Row
					multi
					className="text-start"
				>
					<Col md={6}>
						<PaymentMethodCard />
					</Col>
					<Col md={6}>
						<AutoBillingCurrentSource />
					</Col>
				</Row>
			</FixedWidthWrapper>
		</div>
	);
};
