import React from 'react';
import { isEnvProduction } from 'js/utils/app';
import { TEnv } from 'types/config';
import type { TColorVariant } from '@avast/react-ui-components';
import { Badge } from 'react-bootstrap';
import { CONFIG } from 'config';
import classNames from 'classnames';

const colors: Record<TEnv, TColorVariant> = {
	production: 'danger',
	stage: 'brand',
	stage3: 'tertiary',
	dev: 'info',
	local: 'success',
	test: 'warning',
};

export const EnvLabel = (props: { className?: string }) => {
	if (isEnvProduction) {
		return null;
	}

	return (
		<Badge
			bg={colors[CONFIG.ENV]}
			className={classNames('py-2 px-3', props.className)}
		>
			{CONFIG.ENV}
		</Badge>
	);
};
