import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { TAuthToken } from 'js/reducers/authContextReducer';
import { ILogoutPageState } from 'module/security';
import { RouteName } from 'module/RouteName';

export const useOnNonAuthorizedCallback = () => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(token?: TAuthToken | null) => {
			const state: ILogoutPageState = {
				sessionExpired: true,
				previousLocation: { to: location, state: location.state },
				previousAuthToken: token,
			};

			navigate(RouteName.SECURITY.LOGOUT, { state });
		},
		[navigate, location],
	);
};
