import { Button, H1 } from '@avast/react-ui-components';
import type { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { useInvalidPartnerState } from 'module/security/hooks/useInvalidPartnerState';
import { Navigate, useNavigate } from 'react-router';
import { RouteName } from 'module/RouteName';
import { CONFIG } from 'config';
import type { ILogoutPageState } from 'module/security';


type TWarningContentProps = {
	assistanceText?: boolean;
	translateKey: string;
	salesforceButton?: boolean;
	text?: string | ReactElement;
};

export const WarningContent = (props: TWarningContentProps) => {
	const { t } = useTranslation(securityConfig.trNamespace);
	const { assistanceText, translateKey, salesforceButton, text } = props;
	const invalidPartnerState = useInvalidPartnerState();
	const navigate = useNavigate();

	if (!invalidPartnerState.state || !invalidPartnerState.isCurrentPage) {
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} replace />;
	}

	return (
		<div>
			<H1 className="mb-3" bold>
				{t(`page.${translateKey}.title`)}
			</H1>
			<div>
				<p>{text || <Trans t={t}>{`page.${translateKey}.text`}</Trans>}</p>
				{assistanceText && <p>{t('commonText.assistance')}</p>}
				<p className="mt-8 fw-bold">
					<Trans t={t}>{'commonText.signature'}</Trans>
				</p>
				{salesforceButton && (
					<p className="mt-8">
						<Button
							onClick={() => {
								const state: ILogoutPageState = {
									redirectUrl: CONFIG.APP.SALES_FORCE_URL,
								};

								navigate(RouteName.SECURITY.LOGOUT, { state });
							}}
							variant="primary"
							testId="partnerPortal"
						>
							<span>{t('common.returnToPartnerPortal')}</span>
						</Button>
					</p>
				)}
			</div>
		</div>
	);
};
