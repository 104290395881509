import { Reducer } from 'react';
import { IEntityPartnerDetail, IEntityPartnerIdentifiers } from 'module/partners';
import { isEqual, merge } from 'lodash';
import { TPartnerPriceLists } from 'js/priceList';
import { IUserLocale } from 'types/config';
import { getDefaultUserLocale, resolveLanguageFromLocaleCode } from 'i18n/utils';
import { logError } from 'js/utils/app';

export type TAuthToken = string;

export interface IAuthTokenResponse {
	token: TAuthToken;
}

export interface IAuthDataUser extends IAuthTokenResponse {
	partner: IAuthContextState['partner'];
	company: IAuthContextState['company'];
	priceLists: IAuthContextState['priceLists'];
	locale: IUserLocale;
}

export interface IAuthData extends IAuthDataUser {}

export interface IAuthContextState {
	token: TAuthToken | null;
	timestamp: number;
	partner: IEntityPartnerDetail | null;
	company: IEntityPartnerDetail | null;
	priceLists: TPartnerPriceLists | null;
	isReady: boolean;
	locale: IUserLocale;
}

export type TAuthContextStateAction =
	| { type: 'INIT'; payload: IAuthContextState | null }
	| { type: 'SET'; payload: IAuthContextState }
	| { type: 'RESET' }
	| { type: 'SET_LOCALE_FORMAT'; payload: IUserLocale['format'] }
	| { type: 'SET_AUTH_TOKEN'; payload: IAuthTokenResponse }
	| { type: 'SET_AUTH_COMPANY'; payload: IAuthContextState['company'] }
	| { type: 'SET_AUTH_PARTNER'; payload: IAuthContextState['partner'] }
	| { type: 'UPDATE_TAX_ID'; payload: IEntityPartnerIdentifiers['partyTaxId'] }
	| { type: 'SET_AUTH_DATA'; payload: IAuthData };

export const authContextStateReducer: Reducer<IAuthContextState, TAuthContextStateAction> = (state, action) => {
	switch (action.type) {
		case 'INIT':
			return {
				...state,
				...action.payload,
				isReady: true,
			};
		case 'SET':
			if (!isEqual(action.payload, state) && state.timestamp < action.payload.timestamp) {
				return { ...action.payload };
			}
			return state;
		case 'SET_AUTH_TOKEN':
			return {
				...state,
				token: action.payload.token,
				isReady: true,
				timestamp: Date.now(),
			};
		case 'SET_AUTH_COMPANY':
			return {
				...state,
				company: action.payload,
				timestamp: Date.now(),
			};
		case 'SET_AUTH_PARTNER':
			return {
				...state,
				partner: action.payload,
				locale: {
					...state.locale,
					language: resolveLanguageFromLocaleCode(action.payload?.locale),
				},
				timestamp: Date.now(),
			};
		case 'SET_LOCALE_FORMAT':
			return {
				...state,
				locale: {
					...state.locale,
					format: action.payload,
				},
				timestamp: Date.now(),
			};
		case 'SET_AUTH_DATA':
			return {
				...state,
				...action.payload,
				isReady: true,
				timestamp: Date.now(),
			};
		case 'UPDATE_TAX_ID':
			if (state.company) {
				return {
					...state,
					company: merge(state.company, { identifiers: { partyTaxId: action.payload } }),
					timestamp: Date.now(),
				};
			}
			if (state.partner) {
				return {
					...state,
					partner: merge(state.partner, { identifiers: { partyTaxId: action.payload } }),
					timestamp: Date.now(),
				};
			}

			return state;
		case 'RESET':
			return { ...authContextResetState(state), isReady: true, timestamp: Date.now() };
		default:
			logError('Not supported action', action);
			return state;
	}
};

export const authContextInitialState: IAuthContextState = {
	token: null,
	partner: null,
	company: null,
	priceLists: null,
	timestamp: Date.now(),
	isReady: false,
	locale: getDefaultUserLocale(),
};

export const authContextResetState = (state?: IAuthContextState | null): IAuthContextState => {
	// Reset state, but keep previous locale if any
	return merge<object, IAuthContextState, Partial<IAuthContextState>>({}, authContextInitialState, {
		locale: state?.locale,
	});
};
